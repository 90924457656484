import React from 'react'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { ImgWebp } from 'src/styles/imgWebp'
import BaixarEbookForm from '../../components/BaixarEbookForm/_index'

import { Section } from './style'

export const ComoAbrirUmaEmpresa = () => {
  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Como abrir uma empresa?',
    element_action: 'click button',
    element_name: 'Baixar eBook gratuito',
  }

  return (
    <Section id='first-section' className='bg-white py-5 d-lg-flex align-items-lg-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 mb-4 mb-md-0'>
            <ImgWebp
              breakPointsSize={{ sm: '320px', md: '290px', lg: '379px', xl: '400px' }}
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/hero-banner-conta-pj/image.png'
              alt='Empreendedora sorridente usando um tablet'
            />
            <p className='fs-16 lh-20 fs-md-20 lh-md-25 text-orange--extra fw-700 mb-3'>Ebook</p>
            <h1 className='font-sora fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 fw-600 mb-3 text-grayscale--500'>
              Como abrir <span className='d-md-block'>uma empresa?</span>
            </h1>
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <BaixarEbookForm dataLayer={dataLayer} />
          </div>
        </div>
      </div>
    </Section>
  )
}
