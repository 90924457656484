import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import SimplifiqueAAberturaJSON from '../../assets/data/simplifique-a-abertura.json'

import { Section } from './style'
import ScrollTo from 'src/components/ScrollTo'

interface IIndicateJson {
  icon: string;
  description: string;
}

const SimplifiqueAAbertura = () => {
  return (
    <Section className='py-5 bg-blue--light'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-10 col-xl-8 mx-auto px-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-center fw-600 font-sora text-grayscale--500 mb-3'>Simplifique a abertura do seu CNPJ <span className='d-md-block'>e regularize o seu negócio.</span></h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-21 text-center text-grayscale--500 mb-4'>
              Veja o que preparamos para te ajudar nessa nova jornada!
            </p>
          </div>

          <div className='row d-flex'>
            {SimplifiqueAAberturaJSON.map((data: IIndicateJson) => (
              <div key={data.icon} className='d-flex col-12 col-md-6 mb-4'>
                <div className='col-12 col-md-12'>
                  <div className='bg-white d-flex align-items-center rounded-3 p-3 h-100'>
                    <div>
                      <div className='d-flex justify-content-center align-items-center bg-green--extralight icon-container'>
                        <OrangeIcon size='MD' color='#FFFFFF' icon={data.icon} />
                      </div>
                    </div>
                    <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 text-grayscale--400 mb-0 ml-3'>{data.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className='col-12 text-center'>
            <ScrollTo
              to='#first-section'
              section='dobra_02'
              sectionName='Veja o que preparamos para te ajudar nessa nova jornada!'
              elementName='Baixar eBook gratuito'
              title='Baixar eBook gratuito'
            >
              <button
                className='btn btn-orange bg-orange--extra text-white text-none rounded-2 mt-4'
              >
                Baixar eBook gratuito
              </button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SimplifiqueAAbertura
