import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const OParceiroParaSimplificarSection = styled.section`
  .mobile-size {
    width: 80%;
  }
  .text-div {
    @media ${device.desktopLG} {
      flex-grow: 1;
    }
  }

  a {
    width: 312px;
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
  }
`

export const Button = styled.div`
  button, a {
    text-transform: none;
  }
`
