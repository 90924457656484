import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import OParceiroParaSimplificarJSON from '../../assets/data/o-parceiro-para-simplificar.json'

import usePageQuery from '../../pageQuery'

import { OParceiroParaSimplificarSection, Button } from './style'

type ItemProps = {
  icon: string;
  description: string;
  OrangeDS?: boolean;
}

const OParceiroParaSimplificar = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://conta-digital-pj.bancointer.com.br/login?utm_source=ebook&utm_medium=botao&utm_campaign=aquisicao_ebook_pj_botao&utm_term=conta-digital-pj&utm_content=como_abrir_uma_empresa'

  return (
    <OParceiroParaSimplificarSection className='bg-white'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-lg-between'>
          <div className='col-12 col-md-6 col-lg-6 mt-xl-5'>
            <div className='justify-content-xl-end'>
              <div className='text-div text-xl-left pl-0'>

                <div className='col-12 d-md-none'>
                  <Img
                    fluid={data.oParceiroParaSimplificar.fluid} className='mobile-size'
                    alt='Super app Inter Empresas em destaque no smartphone, ao lado do cartão Inter Empresas.'
                  />
                </div>

                <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 font-sora text-grayscale--500 mt-4 mb-3 mb-lg-4'>
                  Inter Empresas: <span className='d-block'>O parceiro certo para simplificar o seu negócio.</span>
                </h2>

                <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4'>
                  Veja como nossas soluções podem te ajudar:
                </p>
              </div>
            </div>

            { OParceiroParaSimplificarJSON.map((item: ItemProps) => (
              <div className='d-flex flex-row mb-4 align-items-center' key={item.icon}>
                <div>
                  <OrangeIcon size='MD' color='#07605B' icon={item.icon} className='mr-3' />
                </div>
                <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
              ))}

            <div className='mt-4 mt-lg-4'>
              <Button className='d-flex justify-content-center justify-content-lg-start mt-md-5'>
                <a
                  href={url}
                  target='_blank'
                  rel='noreferrer'
                  title='Abrir conta Inter Empresas'
                  className='btn btn-orange--extra btn--lg fs-14 fw-700 rounded-3 mw-100'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: 'Inter Empresas: O parceiro certa para simplificar o seu negócio.',
                      element_action: 'click button',
                      element_name: 'Abrir conta Inter Empresas',
                      redirect_url: url,
                    })
                  }}
                >
                  Abrir conta Inter Empresas
                </a>
              </Button>
            </div>
          </div>

          <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-6'>
            <Img
              fluid={data.oParceiroParaSimplificar.fluid}
              alt='Super app Inter Empresas em destaque no smartphone, ao lado do cartão Inter Empresas.'
              className='float-lg-right'
            />
          </div>
        </div>
      </div>
    </OParceiroParaSimplificarSection>
  )
}

export default OParceiroParaSimplificar
