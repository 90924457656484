import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import BgSM from '../../assets/images/background-form-sm.png'
import BgMD from '../../assets/images/background-form-md.png'
import BgLG from '../../assets/images/background-form-lg.png'
import BgXL from '../../assets/images/background-form-xl.png'

export const Section = styled.section`
  background-image: url(${BgSM});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
  background-size: contain;
  max-height: 965px;

  @media ${device.tablet} {
    background-image: url(${BgMD});
    background-position-x: right;
    background-position-y: top;
    background-size: contain;
  }

  @media ${device.desktopLG} {
    background-image: url(${BgLG});
  }

  @media ${device.desktopXL} {
    background-image: url(${BgXL});
    background-size: auto;
  }

  @media ${device.desktopXXXL} {
    background-size: 1000px;
    height: 910px;
  }
`
