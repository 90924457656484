import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: -20px;
  z-index: 10;
  outline: none !important;

  @media (min-width: ${breakpoints.md}) {
    top: -16px;
  }
`

export const FormSent = styled.div`
  background-color: ${white};
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  width: 312px;
  height: 335px;
  top: 27vh;
  border-radius: 8px;
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    width: 453px;
    height: 299px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 521px;
    height: 335px;
    top: 27vh;
  }
`

export const FormError = styled.div`
  background-color: ${white};
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  width: 312px;
  height: 290px;
  top: 27vh;
  border-radius: 8px;
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    width: 435px;
    height: 289px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 511px;
    height: 308px;
    top: 27vh;
  }
`
