import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import { ComoAbrirUmaEmpresa } from './sections/como-abrir-uma-empresa/_index'
import SimplifiqueAAbertura from './sections/simplifique-abertura/_index'
import OParceiroParaSimplificar from './sections/o-parceiro-para-simplificar/_index'

const ComoAbrirEmpresa = () => {
  return (
    <Layout pageContext={pageContext}>
      <ComoAbrirUmaEmpresa />
      <SimplifiqueAAbertura />
      <OParceiroParaSimplificar />
    </Layout>
  )
}

export default ComoAbrirEmpresa
